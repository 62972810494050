import React from 'react'
import Layout from 'layout/base'
import Seo from 'components/seo'
import PageTemplate from 'templates/pageTemplate'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'

interface PrivacyPolicyPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const PrivacyPolicyPage = ({ pageContext, location }: PrivacyPolicyPageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs.map(crumb => {
    return crumb.crumbLabel in siteStructure
      ? {
          ...crumb,
          ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
        }
      : { ...crumb }
  })

  return (
    <Layout pageType="page" breadcrumbItems={breadcrumbItems} ctaType="type1">
      <Seo
        title={`プライバシーポリシー${globalTitle(location.pathname)}`}
        description="成績アップを目指すためのオンライン個別指導塾、Fit NET STUDYの個人情報保護方針のページです。"
        path={location.pathname}
      />
      <PageTemplate
        headingText="プライバシーポリシー"
        contentElement={
          <>
            <h2>個人情報保護方針</h2>
            <h3>1.遵守規定の策定及び継続的改善</h3>
            <p>
              CKCネットワーク株式会社（以下「当社」といいます。）は、従業員に個人情報保護の重要性を認識させ、個人情報を適切に利用し、保護するための社内規定を策定し、これを遵守します。
              <br />
              更に、これを維持し、継続的に改善して参ります。
            </p>
            <h3>2.個人情報の取得・利用・提供</h3>
            <p>事業の内容及び規模を考慮した適切な個人情報の取得、利用及び提供を定めた社内規定を遵守します。</p>
            <h3>3.安全対策の実施</h3>
            <p>
              個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えいなどに関して、予防措置を講ずると共に、万一の発生時には速やかな是正対策を実施します。
            </p>
            <h3>4.法令・規範の遵守</h3>
            <p>
              当社は、個人情報の取り扱いにおいて当該個人情報の保護に適用される法令及びその他の規範を遵守します。
              <br />
              また、当社の個人情報保護に関する規定等をこれらの法令及びその他の規範に適合させていきます。
            </p>
            <p className="u-text-right">
              CKCネットワーク株式会社
              <br />
              代表取締役 山崎 朋宏
            </p>
            <h2>個人情報の取り扱いについて</h2>
            <h3>1.個人情報保護に対する当社の基本的姿勢について</h3>
            <p>当社は個人情報保護法の趣旨を尊重し、これを遵守するために「個人情報保護方針」「個人情報保護規定」を定め実行します。</p>
            <h3>2.個人情報の利用目的について</h3>
            <p>当社が保有する個人情報は、以下の目的に利用させていただく場合があります。</p>
            <ol>
              <li>学習指導サービス及び商品配送等の契約履行のため。</li>
              <li>問合せ及び資料請求に対する連絡、回答、資料送付等のため。</li>
              <li>学習教材、学校、指導教室、通信添削指導等の教育関連商品・サービスの案内のため。</li>
              <li>講習会、模擬テスト、行事・イベント等のご案内のため。</li>
              <li>成績上位者情報や合格情報、誕生日等の教室内発表のため 。</li>
              <li>学習アドバイスや進路指導等を行うため。</li>
              <li>指導プログラム等の分析・研究のため。</li>
              <li>
                子育て支援、高齢者支援、食品、飲料水、通信回線、通信端末及び周辺機器、各種保険、住宅設備等の生活関連商品・サービスの案内のため。
              </li>
              <li>書籍、旅行、留学、健康支援、観光・飲食施設、各種イベント等の案内のため。</li>
              <li>コンサルティングサービス、人材募集・採用情報等の案内のため。</li>
              <li>訂正・追加などの情報のメンテナンスを行うため。</li>
            </ol>
            <ul>
              <li>
                お客様相談室及び各問合わせ窓口における通話内容の記録およびお客様との商談・交渉内容の記録について、正確な情報の把握とサービス向上に向けた社員教育等に利用させていただく場合がございます。
              </li>
              <li>
                訪問や電話によって取得した家庭環境等の情報につきましては、商品・サービスのご提供やご案内に必要な範囲で他の担当者に伝達し、会話の行き違いや失礼がないように担当者間で共有させていただく場合がございます。
              </li>
            </ul>
            <h3>3.当社が取得する個人情報について</h3>
            <p>
              当社は、お取引をいただいたお客様の個人情報、及び当社の取扱商品等を紹介する対象となる方の個人情報を保有しています。これらの個人情報は、ご契約者様に関しては交付書面（申込書・契約書等）に記された情報や契約の履行に伴って発生する情報であり、今後当社の取扱商品等を紹介する対象となる方に関しては資料請求の際に記された情報や電話帳情報・住宅地図情報など専門業者から提供を受けた情報、また、販売員が訪問した際に取得する情報などです。当社は、個人情報の取得にあたっては、適法かつ公正な手段によりこれを行います。
            </p>
            <h3>4.個人情報の第三者への提供について</h3>
            <p>
              当社では上記利用目的④の範囲内において、学校名・学年・氏名・学習成果・誕生日等の情報を教室内において公表させていただく場合がございます。ただしご本人又はそのご家族から公表停止の請求があった場合には合理的な範囲でこれを承ります。また、当社は保有する個人情報を、お客様に有益な情報発信・提案等を実施し又はそれらをサポートする提携企業に提供する場合があります。その場合、提供されるデータ項目は氏名・住所・電話番号・Ｅメール・生年月日・兄弟構成・営業履歴・問合履歴等で、提供の手段は、書面・電話での口頭伝達・ファックス・電磁的記録媒体の受渡・電子メール等の電磁的通信手段等で行います。
              <br />
              この場合（下記の場合を除いて）、お客様から提供に対する停止のお申し出をいただいた場合は、法令に従い、申し出以降の提供を停止いたします。
            </p>
            <ol>
              <li>お客様の提供に関する同意が別途ある場合</li>
              <li>お客様又は他の第三者の生命、身体又は財産の保護のために必要であって、お客様の同意を得ることが困難である場合</li>
              <li>法的な命令等により個人情報の開示が求められた場合</li>
            </ol>
            <h3>5.個人情報の処理に係る外部委託について</h3>
            <p>
              当社が保有する個人情報は、その利用目的の実施に必要な範囲において業務委託先に預託することがあります。当該委託にあたっては、個人情報保護水準を十分に満たしている業務委託先を選定し、機密保持契約を締結するとともに、適切な管理監督を行います。
            </p>
            <h3>6.個人データの安全管理措置について</h3>
            <p>当社が保有する個人データについては、以下の安全管理措置を講じています。</p>
            <ul>
              <li>
                （内部規律の整備）個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等についての基本方針を策定しています。また、個人情報の取得、利用、提供、廃棄といった段階ごとに、個人情報取扱方法や担当者及びその任務等について規程を策定し、定期的に見直しを実施しています。
              </li>
              <li>
                （組織的安全管理措置）個人データの取扱いに関する責任者を設置するとともに、個人データを取り扱う従業者及び当該従業者が取り扱う個人データの範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。また、個人情報の取り扱いについて、定期的な自己点検、他部署監査、外部主体監査を実施しています。
              </li>
              <li>
                （人的安全管理措置）従業員に対して個人情報保護及び情報セキュリティに関する留意事項についての研修を定期的に実施するとともに、従業員の秘密保持に関する事項を就業規則等に盛り込み、社内で周知しています。
              </li>
              <li>
                （物理的安全管理措置）個人データを取り扱う区域において、従業者の入退室管理や持ち込む機器等の制限を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を実施しています。また、個人データを取り扱う機器や、電子媒体等の盗難・紛失等を防止するための措置を講じるとともに、当該機器、電子媒体等を持ち運ぶ場合に容易に個人データが判明しないようにする措置を実施しています。
              </li>
              <li>
                （技術的安全管理措置）アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定するとともに、個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。
              </li>
            </ul>
            <p>
              ※当社が開設しているウェブサイトでは、他のウェブサイトへのリンクを張ることがありますが、当社以外のウェブサイトにおける個人情報の取り扱いについては、当社は責任を負いません。
            </p>
            <h3>7.保有個人データの開示・訂正・消去・利用停止等について</h3>
            <p>
              当社の保有個人データについて、情報主体である本人又は法定代理人から開示・訂正・消去・利用停止等の請求があった場合は、合理的な範囲でこれを承ります。その場合、所定の請求書及び、その請求者が本人であることを証明する資料（代理人の場合は代理人であることを証明する資料）を提示又は提出していただくことがあります。請求方法・手数料等については下記までお問い合わせください。
            </p>
            <p className="u-text-right">
              お問い合わせ先
              <br />
              CKCネットワーク株式会社
              <br />
              フリーコール 0120-937-380
            </p>
          </>
        }
      />
    </Layout>
  )
}

export default PrivacyPolicyPage
